import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap/all";
import { TextPlugin } from "gsap/TextPlugin";
import { Helmet } from "react-helmet";
import { simulateTowels } from "../utils/towels";

const plugins = [TextPlugin];
const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
gsap.registerPlugin(TextPlugin);
const tl = gsap.timeline({ repeat: 100 });
tl.delay(2);

function getRandomSeed() {
  return Math.round(Math.random() * 1000000000);
}

// markup
export default function IndexPage() {
  const [seed, toggleSeed] = useState(getRandomSeed());

  const containerRef = useRef();
  const canvasRef = useRef();
  const textRef1 = useRef();
  const textRef2 = useRef();

  useEffect(() => {
    const containterWidth = containerRef.current.clientWidth;
    const width = containterWidth - containterWidth * 0.4;
    const height = width;
    const scale = window.devicePixelRatio;

    canvasRef.current.style.width = width + "px";
    canvasRef.current.style.height = height + "px";

    canvasRef.current.width = Math.floor(width * scale);
    canvasRef.current.height = Math.floor(height * scale);

    simulateTowels(canvasRef.current, scale, width, height, seed);

    tl.to(textRef1.current, {
      duration: 3,
      text: "|",
      ease: "cubic",
    })
      .to(textRef1.current, {
        duration: 3,
        text: "Олексій робе,",
        ease: "cubic",
      })
      .to(textRef2.current, {
        duration: 3,
        text: "|",
        ease: "cubic",
      })
      .to(textRef2.current, {
        duration: 3,
        text: "малює графіки і жартує.",
        ease: "cubic",
      })
      .to(textRef1.current, {
        duration: 3,
        text: "|",
        ease: "cubic",
      })
      .to(textRef1.current, {
        duration: 3,
        text: "Oleksii works,",
        ease: "cubic",
      })
      .to(textRef2.current, {
        duration: 3,
        text: "|",
        ease: "cubic",
      })
      .to(textRef2.current, {
        duration: 3,
        text: "makes charts, and cracks jokes.",
        ease: "cubic",
      });
  }, []);

  useEffect(() => {
    const width = canvasRef.current.width;
    const height = width;
    const scale = window.devicePixelRatio;

    simulateTowels(canvasRef.current, 2, width, height, seed);
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>lxii works</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div
        ref={containerRef}
        style={{
          margin: "auto",
          border: "2px solid gray",
          boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.3)",
          backgroundColor: "rgb(255,255,235)",
          marginTop: "5%",
          maxWidth: "450px",
          padding: "30px 50px",
          fontFamily: "sans-serif",
        }}
      >
        <h1 ref={textRef1}>Oleksii works,</h1>
        <h3 ref={textRef2}>makes charts, and cracks jokes.</h3>
        <div style={{ textAlign: "center" }}>
          <canvas
            onClick={() => toggleSeed(getRandomSeed)}
            ref={canvasRef}
            style={{ margin: "0 auto" }}
          />
        </div>
      </div>
    </>
  );
}
