function leastFactor(n) {
  if (isNaN(n) || !isFinite(n)) return NaN;
  if (n === 0) return 0;
  if (n % 1 || n * n < 2) return 1;
  if (n % 2 === 0) return 2;
  if (n % 3 === 0) return 3;
  if (n % 5 === 0) return 5;

  let m = Math.sqrt(n);
  for (let i = 7; i <= m; i += 30) {
    if (n % i === 0) return i;
    if (n % (i + 4) === 0) return i + 4;
    if (n % (i + 6) === 0) return i + 6;
    if (n % (i + 10) === 0) return i + 10;
    if (n % (i + 12) === 0) return i + 12;
    if (n % (i + 16) === 0) return i + 16;
    if (n % (i + 22) === 0) return i + 22;
    if (n % (i + 24) === 0) return i + 24;
  }

  return n;
}

function isPrime(n) {
  if (isNaN(n) || !isFinite(n) || n % 1 || n < 2) return false;
  if (n == leastFactor(n)) return true;
  return false;
}

export function simulateTowels(canvas, scale, width, height, seed) {
  let context = canvas.getContext("2d");
  context.save();
  context.scale(scale, scale);
  context.clearRect(0, 0, width, height);
  let center = width / 4;
  let root2 = Math.sqrt(2);

  //context.fillStyle = "rgb(255,255,235)";
  context.fillStyle = "black";
  //context.fillRect(0, 0, width, height);
  context.beginPath();
  context.arc(center, center, width / 4 - 5, 0, 2 * Math.PI);
  context.fill();
  context.beginPath();
  context.arc(center, center, width / 4 - 8, 0, 2 * Math.PI);
  context.strokeStyle = "rgb(255,255,235)";
  context.lineWidth = "2";
  context.stroke();

  context.fillStyle = "red";

  context.translate(0, 0);
  for (let x = 0; x <= width / 2; x++) {
    for (let y = 0; y <= height / 2; y++) {
      if (
        isPrime(x ^ (y + seed)) &&
        (x % 2 === 0 || y % 2 === 0) &&
        (x - center) ** 2 + (y - center) ** 2 <= (center - 20) ** 2
      ) {
        context.fillRect(x, y, 2, 2);
        //        context.beginPath();
        //      context.arc(x, y, 2, 0, 2 * Math.PI);
        //    context.fill();
      }
    }
  }
  context.restore();
}
